<template>
  <section class="ci-vehicle-card" @click="showVehicle(id)">
    <div class="ci-vehicle-card__half-width">
      <div class="ci-vehicle-card__checkbox-container" @click.stop>
        <RdxCheckbox
          v-model="cardChecked"
          class="ci-vehicle-card__checkbox"
          @change="toggleCheckbox(id)"
        />
      </div>
      <CiCardImage :cover-photo="coverPhoto">
        <div class="ci-vehicle-card__indicators">
          <CiVideoIndicator v-if="videoUrl" />
          <CiBadge>{{ imagesNumberText }}</CiBadge>
        </div>
      </CiCardImage>

      <div class="ci-vehicle-card__specs-column">
        <CiHeading level="2" class="ci-vehicle-card__model">
          {{ brandName }}
          <template v-if="nameplateName">{{ nameplateName }}</template>
        </CiHeading>
        <CiSpec :spec="version">
          <CiHeading level="3" class="ci-vehicle-card__version">
            {{ version }}
          </CiHeading>
        </CiSpec>
        <ul class="ci-vehicle-card__specs-row">
          <CiSpec :spec="engineCapacity" class="ci-vehicle-card__spec">
            {{ engineCapacity }}
            {{ $t(`global.engine_capacity_unit.${engineCapacityUnit}`) }}
          </CiSpec>
          <CiSpec :spec="`${power} BG`" class="ci-vehicle-card__spec" />
          <CiSpec
            v-for="engine in engineType"
            :key="engine"
            :spec="$t(`global.engines.${engine}`)"
            class="ci-vehicle-card__spec"
          />
          <CiSpec :spec="colorName" class="ci-vehicle-card__spec" />
        </ul>
        <CiSpec :spec="vin" class="ci-vehicle-card__vin ci-spec--12">
          Ekipman: {{ vin }}
        </CiSpec>
      </div>
    </div>
    <div class="ci-vehicle-card__half-width">
      <div
        class="ci-vehicle-card__half-width ci-vehicle-card__half-width--wrap"
      >
        <div class="ci-vehicle-card__half-width ci-vehicle-card__specs-column">
          <div class="ci-vehicle-card__price-container">
            <CiSpec
              v-if="priceSale"
              :spec="$format.price(priceSale, ownerLocale, currency)"
              color="dark"
              class="ci-spec--16 ci-spec--bold"
            />
            <CiSpec
              v-if="!priceSale"
              :spec="$format.price(priceMsrp, ownerLocale, currency)"
              color="dark"
              class="ci-spec--16 ci-spec--bold"
            />
            <RdxIcon
              v-if="priceHidden"
              v-tooltip="$t('stock.vehicle-card.price-hidden')"
              class="ri-eye-off-line ci-vehicle-card__price-container-icon"
            />
          </div>
          <CiSpec
            v-if="priceSale"
            :spec="$format.price(priceMsrp, ownerLocale, currency)"
            color="light"
          />
          <CiSpec
            v-if="priceMonthly"
            :spec="$format.price(priceMonthly, ownerLocale, currency)"
            color="dark"
            class="ci-spec--16 ci-spec--bold"
          >
            <span>{{
              $format.price(priceMonthly, ownerLocale, currency)
            }}</span>
            <span class="ci-vehicle-card__unit"
              >/{{ $t("stock.monthly-abbreviation") }}</span
            >
          </CiSpec>
        </div>
        <div class="ci-vehicle-card__half-width ci-vehicle-card__specs-column">
          <CiSpec :spec="productionYear" color="dark" class="ci-spec--16" />
          <CiSpec :spec="mileage" color="dark" class="ci-spec--16">
            {{ mileage }}
            <span v-if="mileageUnit" class="ci-vehicle-card__unit">
              {{ $t(`global.mileage_units.${mileageUnit}`) }}
            </span>
          </CiSpec>
        </div>
        <div class="ci-vehicle-card__date">
          <CiSpec :spec="dateCreated" class="ci-spec--12 ci-spec--inline">
            {{ $format.date(dateCreated, $i18n.locale()) }}
          </CiSpec>
          <CiSpec :spec="dateUpdated" class="ci-spec--12 ci-spec--inline">
            ({{ $t("stock.vehicle-card.updated") }}:
            {{ $format.date(dateUpdated, $i18n.locale()) }})
          </CiSpec>
        </div>
      </div>
      <div class="ci-vehicle-card__half-width">
        <div class="ci-vehicle-card__specs-column">
          <CiSpec
            :spec="$t(`global.states.${state}`)"
            class="ci-spec--16"
            color="dark"
          />
          <div>
            <CiSpec
              :spec="status"
              :color="statusTextColor"
              class="ci-spec--16 ci-spec--bold ci-spec--inline"
            >
              {{ $t(`global.availabilities.${status}`) }}
            </CiSpec>
            <CiSpec
              :spec="deliveryDate"
              class="ci-spec--16 ci-spec--inline ci-vehicle-card__unit"
            >
              - {{ deliveryDate }}</CiSpec
            >
          </div>
          <CiSpec :spec="ownerName" class="ci-spec--12 ci-spec--spacious" />
          <CiSpec :spec="ownerCity" class="ci-spec--12 ci-spec--spacious">
            <RdxIcon class="ri-map-pin-line" /> {{ ownerCity }}
          </CiSpec>
        </div>
        <ul class="ci-vehicle-card__edit-menu">
          <li
            v-tooltip.left="$t('stock.vehicle-card.edit-details-tooltip')"
            class="ci-vehicle-card__edit-button"
            @click.stop="editVehicle"
          >
            <RdxIcon class="ri-edit-line rdx-icon--16" />
          </li>
          <li
            v-tooltip.left="$t('global.duplicate')"
            class="ci-vehicle-card__edit-button"
            @click.stop="duplicateVehicle"
          >
            <RdxIcon class="ri-file-copy-line rdx-icon--16" />
          </li>
          <li
            v-tooltip.left="$t('global.remove')"
            class="ci-vehicle-card__edit-button
            ci-vehicle-card__edit-button--remove"
            @click.stop="removeVehicle"
          >
            <RdxIcon class="ri-delete-bin-line rdx-icon--16" />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import CiSpec from "../atoms/CiSpec";
import CiHeading from "../atoms/CiHeading";
import CiVideoIndicator from "../atoms/CiVideoIndicator";
import CiBadge from "../atoms/CiBadge";
import { RdxIcon, RdxCheckbox } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiCardImage from "@/components/molecules/CiCardImage";

export default {
  name: "CiVehicleCard",

  components: {
    CiSpec,
    CiHeading,
    CiVideoIndicator,
    CiBadge,
    RdxIcon,
    RdxCheckbox,
    CiCardImage
  },

  props: {
    id: {
      type: [Number, String],
      default: undefined
    },
    brandName: {
      required: true,
      type: String
    },
    colorName: {
      required: true,
      type: String
    },
    coverPhoto: {
      type: String,
      default: undefined
    },
    currency: {
      required: true,
      type: String
    },
    ownerLocale: {
      required: true,
      type: String
    },
    dateCreated: {
      required: true,
      type: String
    },
    dateUpdated: {
      type: String,
      default: undefined
    },
    deliveryDate: {
      type: String,
      default: undefined
    },
    engineCapacity: {
      type: [Number, String],
      default: undefined
    },
    engineCapacityUnit: {
      type: String,
      default: undefined
    },
    engineType: {
      required: true,
      type: Array
    },
    mileage: {
      type: Number,
      default: undefined
    },
    nameplateName: {
      required: true,
      type: String
    },
    ownerCity: {
      type: String,
      default: undefined
    },
    ownerName: {
      type: String,
      default: undefined
    },
    photos: {
      type: Array,
      default: () => []
    },
    power: {
      required: true,
      type: Number
    },
    priceMonthly: {
      type: [Number, String],
      default: undefined
    },
    priceSale: {
      type: String,
      default: undefined
    },
    priceMsrp: {
      required: true,
      type: String
    },
    priceHidden: {
      required: true,
      type: Boolean
    },
    productionYear: {
      type: Number,
      default: undefined
    },
    state: {
      required: true,
      type: String
    },
    status: {
      required: true,
      type: String
    },
    version: {
      required: true,
      type: String
    },
    videoUrl: {
      type: String,
      default: undefined
    },
    vin: {
      type: String,
      default: undefined
    },
    mileageUnit: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      cardChecked: false
    };
  },

  computed: {
    imagesNumberText() {
      let imagesNumber = this.photos.length;
      if (imagesNumber) {
        return this.$t(
          "stock.vehicle-card.image",
          { imagesNumber },
          imagesNumber
        );
      } else {
        return this.$t("stock.vehicle-card.no-image");
      }
    },

    statusTextColor() {
      if (this.status === "stock") return "green";
      if (this.status === "reserved") return "orange";
      if (this.status === "sold") return "red";
      return "dark";
    }
  },

  methods: {
    showVehicle() {
      this.$emit("show", this.id);
    },
    editVehicle() {
      this.$emit("edit", this.id);
    },
    duplicateVehicle() {
      this.$emit("duplicate", this.id);
    },
    removeVehicle() {
      this.$emit("remove", this.id);
    },
    toggleCheckbox(id) {
      this.$emit("toggle-checkbox", id);
    }
  }
};
</script>

<style lang="scss">
.ci-vehicle-card {
  display: flex;
  border-top: 1px solid $grey-4;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: $grey-8;
  &:hover {
    background: $grey-2;
    .ci-vehicle-card__edit {
      opacity: 1;
    }
  }
}

.ci-vehicle-card__half-width {
  flex: 0 0 50%;
  display: flex;
  &--wrap {
    flex-wrap: wrap;
  }
}

.ci-vehicle-card__specs-column {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.ci-vehicle-card__checkbox-container {
  display: flex;
  align-items: center;
}

.ci-vehicle-card__checkbox {
  margin: auto 16px auto 0;
}

.ci-vehicle-card__indicators {
  position: absolute;
  left: 6px;
  bottom: 6px;
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.ci-vehicle-card__model {
  margin-bottom: 4px;
  transition: all 0.2s ease-in-out;
}

.ci-vehicle-card {
  &:hover {
    .ci-vehicle-card__model {
      color: $blue-6;
    }
  }
}

.ci-vehicle-card__version {
  text-transform: uppercase;
  margin-bottom: 4px;
}

.ci-vehicle-card__specs-row {
  display: flex;
  list-style: none;
  font-size: 14px;
  flex-wrap: wrap;
}

.ci-vehicle-card__spec {
  display: flex;
  align-items: center;
  &:after {
    display: block;
    content: "";
    background: $grey-7;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: 0 4px;
  }
  &:last-child {
    &:after {
      display: none;
      margin-right: 0;
    }
  }
}

.ci-vehicle-card__vin,
.ci-vehicle-card__date {
  margin-top: auto;
}

.ci-vehicle-card__unit {
  font-weight: normal;
  font-size: 14px;
  color: $grey-7;
}

.ci-vehicle-card__price-container {
  display: flex;
  align-items: center;
}

.ci-vehicle-card__price-container-icon {
  margin-left: 7px;
}

.ci-vehicle-card__edit-menu {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  list-style: none;
  margin-left: auto;
  width: 38px;
  background: $grey-1;
  border: 1px solid $grey-5;
  border-radius: 4px;
  padding: 2px;
  align-self: center;
}

.ci-vehicle-card {
  &:hover {
    .ci-vehicle-card__edit-menu {
      opacity: 1;
    }
  }
}

.ci-vehicle-card__edit-button {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: $grey-3;
  }
  &--remove {
    &:hover {
      color: $red-6;
    }
  }
}
</style>
