<template>
  <RdxModal class="ci-import-modal" @close-modal="cancel">
    <template #header>
      <span>
        {{ $t("stock.import-modal.header") }}
      </span>
    </template>
    <div class="ci-import-modal__content">
      <div v-if="loading" class="ci-import-modal__loader">
        <RdxLoader size="medium" />
      </div>
      <section v-if="selectedOption === 'vista'">
        <p class="ci-import-modal__paragraph">
          {{ $t("stock.import-modal.vista-description") }}
        </p>
        <div class="ci-import-modal__file">
          <RdxFilePicker
            :accept="`.pdf, .xls`"
            :disabled="fileLoading"
            :label="$t('stock.import-modal.vista-upload-label')"
            :custom-file-label="$t('stock.import-modal.select-file-button')"
            @change="uploadAttachment"
          />
          <p class="ci-import-modal__tip">
            <RdxLoader v-if="fileLoading" size="small" />
            <span v-else>{{ $t("stock.import-modal.vista-upload-note") }}</span>
          </p>
        </div>
        <p
          v-if="error"
          class="ci-import-modal__paragraph ci-import-modal__error"
        >
          {{ $t("stock.import-modal.vista-upload-error") }}
        </p>
      </section>
      <section v-else-if="selectedOption === 'configurator'">
        <p class="ci-import-modal__paragraph">
          {{ $t("stock.import-modal.config-link-description") }}
        </p>
        <RdxTextarea
          v-model="configUrl"
          class="ci-import-modal__input"
          type="url"
          placeholder="https://"
        >
          <template #label>
            {{ $t("stock.import-modal.config-link-label") }}
          </template>
        </RdxTextarea>
        <p
          v-if="error"
          class="ci-import-modal__paragraph ci-import-modal__error"
        >
          {{ $t("stock.import-modal.config-link-error") }}
        </p>
      </section>
      <section v-else>
        <p class="ci-import-modal__paragraph">
          {{ $t("stock.import-modal.description") }}
        </p>
        <ul class="ci-import-modal__options-list">
          <li class="ci-import-modal__option" @click="selectOption('vista')">
            <RdxIcon
              icon="ri-file-3-line"
              class="rdx-icon--20 rdx-icon--blue ci-import-modal__option-icon"
            />
            <div>
              <CiHeading class="ci-import-modal__option-heading" :level="3">
                {{ $t("stock.import-modal.option-vista-header") }}
              </CiHeading>
              <p class="ci-import-modal__option-description">
                {{ $t("stock.import-modal.option-vista-description") }}
              </p>
            </div>
          </li>
          <li
            class="ci-import-modal__option"
            @click="selectOption('configurator')"
          >
            <RdxIcon
              icon="ri-link"
              class="rdx-icon--20 rdx-icon--blue ci-import-modal__option-icon"
            />
            <div>
              <CiHeading class="ci-import-modal__option-heading" :level="3">
                {{ $t("stock.import-modal.option-config-link-header") }}
              </CiHeading>
              <p class="ci-import-modal__option-description">
                {{ $t("stock.import-modal.option-config-link-description") }}
              </p>
            </div>
          </li>
        </ul>
      </section>
    </div>
    <template #footer>
      <RdxButton class="ci-import-modal__button" outline @click="cancel">
        {{ $t("global.cancel") }}
      </RdxButton>
      <RdxButton
        v-if="!selectedOption"
        class="ci-import-modal__button"
        outline
        @click="createVehicle(null)"
      >
        {{ $t("stock.import-modal.add-manually") }}
      </RdxButton>
      <template v-else>
        <RdxButton
          class="ci-import-modal__button"
          outline
          @click="selectOption(null)"
        >
          {{ $t("stock.import-modal.back") }}
        </RdxButton>
        <RdxButton
          class="ci-import-modal__button"
          filled
          :disabled="nextButtonDisabled"
          @click="createVehicle(selectedOption)"
          @mousedown="launchVistaLoader"
        >
          {{ $t("stock.import-modal.next") }}
        </RdxButton>
      </template>
    </template>
  </RdxModal>
</template>

<script>
import ASUpload from "@/utils/active-storage-upload";
import {
  RdxButton,
  RdxIcon,
  RdxModal,
  // RdxInput,
  RdxLoader,
  RdxFilePicker,
  RdxTextarea
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

export default {
  name: "CiImportModal",

  components: {
    RdxButton,
    RdxIcon,
    RdxModal,
    // RdxInput,
    RdxLoader,
    RdxFilePicker,
    CiHeading,
    RdxTextarea
  },

  data() {
    return {
      selectedOption: null,
      loading: false,
      error: false,
      fileLoading: false,
      vistaLoaded: false,
      signedId: null,
      vistaData: {},
      configUrl: ""
    };
  },

  computed: {
    nextButtonDisabled() {
      return (
        (this.selectedOption === "vista" && !this.vistaLoaded) ||
        (this.selectedOption === "configurator" && !this.configUrl)
      );
    }
  },

  methods: {
    cleanState() {
      this.loading = false;
      this.fileLoading = false;
      this.error = false;
      this.configUrl = "";
    },

    selectOption(option) {
      this.selectedOption = option;
      this.cleanState();
    },
    cancel() {
      this.$emit("cancel");
    },
    uploadAttachment(file) {
      this.vistaLoaded = false;
      this.error = false;
      if (file) {
        this.fileLoading = true;
        const URL = process.env["VUE_APP_API_URL"];
        const uploadedAttachmentPromises = [...file].map(file => {
          return ASUpload(file, URL).then(({ signed_id }) => {
            this.signedId = signed_id;
          });
        });

        Promise.all(uploadedAttachmentPromises).then(() => {
          this.vistaData = {};
          this.$http
            .post("vista_files/features", {
              data: {
                attributes: {
                  file: this.signedId
                }
              }
            })
            .then(response => {
              this.fileLoading = false;
              if (response) {
                this.vistaData = response.data;
                this.vistaLoaded = true;
              }
            })
            .catch(() => {
              this.fileLoading = false;
              this.error = true;
            });
        });
      }
    },

    fetchConfiguratorData() {
      this.error = false;
      this.loading = true;
      this.$http
        .get(`/itg/features?config_url=${encodeURIComponent(this.configUrl)}`)
        .then(response => {
          this.vehicleData = response.data;
          this.goToForm(response.data);
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },

    sendVistaData() {
      this.goToForm(this.vistaData);
    },
    goToForm(vehicleData) {
      this.$router.push({
        name: "CarsInventoryNewVehicle",
        params: { initialData: vehicleData }
      });
    },

    createVehicle(option) {
      if (!option) {
        this.goToForm();
      } else if (option === "vista") {
        this.sendVistaData();
      } else if (option === "configurator") {
        this.fetchConfiguratorData();
      }
    },

    launchVistaLoader() {
      if (this.selectedOption === "vista") this.loading = true;
    }
  }
};
</script>

<style lang="scss">
.ci-import-modal {
  padding: 32px;
  z-index: 100;

  &__content {
    position: relative;
    padding: 24px 32px;
  }

  &__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: $grey-1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__paragraph {
    margin-bottom: 16px;
  }

  &__options-list {
    list-style-type: none;
  }

  &__option {
    display: flex;
    padding: 10px 19px;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: $grey-3;
      .ci-heading {
        color: $blue-6;
      }
    }
  }

  &__option-icon {
    margin-right: 19px;
  }

  &__option-heading {
    font-weight: bold;
  }

  &__option-description {
    color: $grey-7;
  }

  &__file {
    display: flex;
  }

  &__input {
    margin-bottom: 0.25em;
  }

  .rdx-input__label {
    display: flex;
  }

  &__error {
    font-size: 12px;
    color: $red-7;
    margin-bottom: 0;
  }

  &__icon {
    margin-left: 0.3em;
  }

  &__tip {
    font-size: 12px;
    color: $grey-7;
    margin-left: 15px;
    padding-top: 31px;
  }

  &__button {
    &:nth-child(2) {
      margin-right: 0.5em;
    }

    &.rdx-button:first-child {
      margin-right: auto;
    }
  }
  .rdx-file-picker {
    &__file {
      cursor: default;
      &:hover {
        background: $grey-3;
      }
    }

    &__file-name {
      color: $grey-8;
    }
  }
}
</style>
