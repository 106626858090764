<template>
  <RdxIcon :class="classes" />
</template>

<script>
import { RdxIcon } from "@raffine/rdx-ui/lib/rdx-ui.umd";

export default {
  name: "CiVideoIndicator",

  components: {
    RdxIcon
  },
  props: {
    iconSize: {
      type: [Number, String],
      default: 24
    }
  },

  computed: {
    classes() {
      return [
        `rdx-icon--${this.iconSize}`,
        "rdx-icon--red",
        "ri-youtube-fill",
        "ci-video-indicator"
      ];
    }
  }
};
</script>

<style lang="scss">
.ci-video-indicator {
  background: radial-gradient(
    circle,
    rgb(255, 255, 255) 30%,
    rgba(255, 255, 255, 0) 30% 100%
  );
  line-height: 1;
}
</style>
