<template>
  <div class="stock-view">
    <div ref="list-items" class="list-items stock-view__list-items p-4">
      <div class="list-items-header">
        <div>
          <CiHeading level="1">{{ $t("stock.header") }}</CiHeading>
          <div v-if="vehicles">
            {{
              $t("stock.results", {
                from: pagination.from || 0,
                to: pagination.to || 0,
                count: pagination.count || 0
              })
            }}
          </div>
        </div>
        <div style="display: flex; flex-direction: row;">
          <CiSort :saved-state="sortSettings" @change="sortChange" />
          <RdxButton
            filled
            variant="red"
            :disabled="deleteButtonDisabled"
            @click="openDeleteModal(selectedVehicles)"
          >
            <RdxIcon icon="ri-delete-bin-line" />
            <span>{{ $t("global.delete") }}</span>
          </RdxButton>
        </div>
      </div>

      <div
        v-if="loading"
        class="stock-view__empty-state"
        data-cy="stock-view-loader"
      >
        <RdxLoader size="large" />
      </div>

      <div
        v-else-if="Object.keys(vehicles).length"
        class="list-items-content"
        data-cy="list-items-content"
      >
        <template v-for="vehicle in vehicles">
          <CiVehicleCard
            :id="parseInt(vehicle.id)"
            :key="vehicle.id"
            :brand-name="vehicle.attributes.nameplate_brand_name"
            :color-name="vehicle.attributes.color_name"
            :cover-photo="vehicle.attributes.cover_photo_small"
            :currency="vehicle.attributes.currency"
            :owner-locale="vehicle.attributes.owner_locale"
            :date-created="vehicle.attributes.created_at"
            :date-updated="vehicle.attributes.updated_at"
            :delivery-date="vehicle.attributes.delivery_date"
            :engine-capacity="vehicle.attributes.engine_capacity_normalized"
            :engine-capacity-unit="vehicle.attributes.engine_capacity_unit"
            :engine-type="vehicle.attributes.engine_type"
            :mileage="vehicle.attributes.mileage"
            :nameplate-name="vehicle.attributes.nameplate_name"
            :owner-city="vehicle.attributes.owner_city"
            :owner-name="vehicle.attributes.owner_name"
            :photos="vehicle.relationships.photos.data"
            :power="vehicle.attributes.power"
            :price-monthly="vehicle.attributes.monthly_price"
            :price-sale="vehicle.attributes.sale_price"
            :price-msrp="vehicle.attributes.msrp_price"
            :price-hidden="vehicle.attributes.price_hidden || false"
            :production-year="vehicle.attributes.production_year"
            :state="vehicle.attributes.state"
            :status="vehicle.attributes.status"
            :version="vehicle.attributes.version"
            :video-url="vehicle.attributes.video_url"
            :vin="vehicle.attributes.external_id"
            :mileage-unit="vehicle.attributes.mileage_unit"
            @show="navigateTo('CarsInventoryVehicleDetailsPreview', vehicle.id)"
            @edit="navigateTo('CarsInventoryVehicleEdit', vehicle.id)"
            @duplicate="navigateTo('CarsInventoryVehicleDuplicate', vehicle.id)"
            @remove="openDeleteModal([parseInt(vehicle.id)])"
            @toggle-checkbox="updateSelectedVehiclesList"
          />
        </template>
      </div>

      <CiEmptyState
        v-else-if="userIsSearchingForVin"
        class="stock-view__empty-state"
      >
        <template #header>
          {{ $t("global.vin-empty-state.header") }}
        </template>
        <template #description>
          <p class="pt-1 mb-3">
            {{ $t("global.vin-empty-state.description") }}
          </p>
        </template>
      </CiEmptyState>

      <div v-else class="stock-view__empty-state">
        <RdxIcon icon="ri-car-line" class="rdx-icon--38 rdx-icon--grey" />
        <strong>{{ $t("stock.no-vehicles.header") }}</strong>
        <p class="stock-view__empty-state-paragraph">
          {{ $t("stock.no-vehicles.description") }}
        </p>
        <RdxButton outline @click="openConfigModal">
          <RdxIcon icon="ri-add-line" />
          <span>{{ $t("stock.add-vehicle-button") }}</span>
        </RdxButton>
      </div>

      <div
        v-if="vehicles && pagination"
        class="stock-view__pagination-wrapper my-3"
      >
        <Paginate
          :value="pageID"
          :page-count="pagination.pages"
          :click-handler="handlePageChange"
          prev-text="<i class='rdx-icon rdx-icon--18 ri-arrow-left-s-line'></i>"
          next-text="<i class='rdx-icon ri-arrow-right-s-line'></i>"
          container-class="rdx-pagination"
          page-link-class="rdx-button rdx-button--rect rdx-button--outline"
          prev-link-class="rdx-button rdx-button--rect rdx-button--outline"
          next-link-class="rdx-button rdx-button--rect rdx-button--outline"
          active-class="active"
        >
        </Paginate>
      </div>
    </div>

    <portal to="cars-inventory-nav">
      <RdxButton filled @click="openConfigModal">
        <RdxIcon icon="ri-add-line mr-1" />
        {{ $t("stock.add-vehicle-button") }}
      </RdxButton>
    </portal>

    <CiDeleteModal
      v-if="deleteModalOpened"
      :with-confirmation="deleteModalData.withConfirmation"
      :heading="deleteModalData.heading"
      :paragraph="deleteModalData.paragraph"
      :delete-text="$t('stock.remove-modal.confirm-action')"
      :cancel-text="$t('global.cancel')"
      @cancel="closeDeleteModal"
      @delete="deleteVehicles(vehiclesToDelete)"
    />
    <CiImportModal v-if="configModalOpened" @cancel="closeConfigModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import { RdxButton, RdxIcon, RdxLoader } from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";
import CiVehicleCard from "@/components/CiVehicleCard/CiVehicleCard";
import CiDeleteModal from "@/components/CiDeleteModal/CiDeleteModal";
import CiSort from "@/components/CiSort/CiSort";
import CiImportModal from "@/components/CiImportModal/CiImportModal";
import CiEmptyState from "@/components/molecules/CiEmptyState";

export default {
  name: "StockView",

  components: {
    RdxButton,
    RdxIcon,
    RdxLoader,
    Paginate,
    CiHeading,
    CiVehicleCard,
    CiDeleteModal,
    CiSort,
    CiImportModal,
    CiEmptyState
  },

  data() {
    return {
      vehicles: {},
      pagination: {
        pages: 0
      },
      itemsPerPage: 10,
      selectedVehicles: [],
      deleteModalOpened: false,
      vehiclesToDelete: [],
      configModalOpened: false,
      loading: true
    };
  },

  computed: {
    pageID() {
      return parseInt(this.$route.params.id) || undefined;
    },
    deleteButtonDisabled() {
      return !this.selectedVehicles.length;
    },
    deleteModalData() {
      let vehiclesNumber = this.vehiclesToDelete.length;
      let multipleVehicles = vehiclesNumber > 1;
      return {
        withConfirmation: multipleVehicles,
        heading: this.$t("stock.remove-modal.header", {}, vehiclesNumber),
        paragraph:
          this.$t(
            "stock.remove-modal.description",
            { vehiclesNumber },
            vehiclesNumber
          ) +
          "\n" +
          this.$t("stock.remove-modal.note")
      };
    },

    userIsSearchingForVin() {
      return !!this.vinNumber;
    },
    ...mapGetters({
      queryString: "sortFilter/string",
      sortSettings: "sortFilter/sortSetting",
      vinNumber: "sortFilter/vinNumber"
    })
  },

  watch: {
    "$route.params.id": {
      immediate: true,
      handler(newPage) {
        this.getVehicles(newPage);
      }
    },

    queryString: {
      handler() {
        if (this.pageID === 1) {
          this.getVehicles(1);
        } else {
          this.handlePageChange(1);
        }
      }
    }
  },

  methods: {
    handlePageChange(page) {
      this.$router.push({
        name: "CarsInventoryStockID",
        params: {
          id: page
        }
      });
    },

    getVehicles(page) {
      if (page && this.$refs["list-items"])
        this.$scrollTo(".stock-view__list-items", {
          container: ".cars-inventory-view__inner",
          duration: 800
        });

      this.$http
        .get(
          "vehicles?items=" +
            this.itemsPerPage +
            "&page=" +
            (this.pageID || 1) +
            this.queryString
        )
        .then(({ data: { data, meta } }) => {
          this.pagination = meta.pagination;
          this.vehicles = data;
          this.loading = false;
        });
    },

    navigateTo(name, id) {
      this.$router.push({ name, params: { id } });
    },
    updateSelectedVehiclesList(id) {
      if (this.selectedVehicles.includes(id)) {
        this.selectedVehicles = this.selectedVehicles.filter(
          item => item !== id
        );
      } else this.selectedVehicles.push(id);
    },

    openDeleteModal(vehiclesList) {
      this.vehiclesToDelete = vehiclesList;
      this.deleteModalOpened = true;
    },
    closeDeleteModal() {
      this.deleteModalOpened = false;
      this.vehiclesToDelete = [];
    },
    deleteVehicles(vehiclesList) {
      const DELETED_VEHICLES = vehiclesList.map(id => {
        return this.$http.delete(`vehicles/${id}`);
      });
      Promise.all(DELETED_VEHICLES).then(() => {
        this.getVehicles();
        this.selectedVehicles = [];
        this.$toast.success(this.$t("stock.vehicle-deleted-successfull"));
      });
    },

    openConfigModal() {
      this.configModalOpened = true;
    },
    closeConfigModal() {
      this.configModalOpened = false;
    },
    sortChange(state) {
      this.$store.commit("sortFilter/SET_SORT_STATE", state);
    }
  }
};
</script>

<style lang="scss">
.rdx-pagination {
  display: flex;
  gap: 0.25rem;
  list-style: none;
  margin-bottom: 1rem;
  user-select: none;

  .active .rdx-button {
    border: 1px solid $blue-6;
    color: $blue-6;
  }

  .disabled .rdx-button {
    border: 1px solid $grey-5;
    color: $grey-6;
    background: $grey-3;
    cursor: not-allowed;

    &:focus {
      box-shadow: none;
    }
  }
}

.list-items-header {
  width: 100%;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-items-content {
  border-bottom: 1px solid $grey-5;
}

.stock-view__list-items {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.stock-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__pagination-wrapper {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
  }
}

.stock-view__empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.stock-view__empty-state-paragraph {
  font-size: 12px;
  margin: 4px 0 16px 0;
  color: $grey-7;
}
</style>
